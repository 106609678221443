let echarts = require('echarts/lib/echarts')
// 引入柱状图组件

require('echarts/lib/chart/map')
require('echarts/lib/chart/pie')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
require('echarts/lib/chart/radar')
// 引入提示框和title组件
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
require("echarts/lib/component/toolbox")
require('echarts/lib/component/legend')
require("echarts/lib/component/dataZoom")
require("echarts/lib/component/markLine")

import sz from "./shenzhen.json";


const echart = {

    //柱状图
    showColumCategory(that, el, source, series) {
        const myChart = echarts.init(document.getElementById(el));
        let option = {

            legend: {
                itemWidth: 14,
                itemHeight: 14,
                textStyle: {
                    fontSize: 14,
                    color: '#fff'
                }
            },
            tooltip: {},
            dataset: {
                source: source
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    textStyle: {
                        color: '#fff', //坐标值得具体的颜色                   
                    }
                }
            },
            yAxis: {
                show: false
            },
            series: series,
            grid: {
                left: 20,
                top: 45,
                right: 30,
                bottom: 36,
            }
        }
        myChart.setOption(option);
        that.charts.push(myChart);
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
    //不同颜色柱状图
    columCategory: (that, el, source, series, comy) => {
        const myChart = echarts.init(document.getElementById(el));
        let option = {
            xAxis: {
                type: 'category',
                data: source,
                axisLabel: {
                    interval: 0,
                    margin: 10,
                    textStyle: {
                        fontSize: 10,
                    },
                },

            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: comy ? '{value}%' : '{value}',
                    textStyle: {
                        fontSize: 10,
                    },
                },
                // max: 3000,
                min: 0,
            },
            series: [{
                data: series,
                type: 'bar',
                barWidth: '50%',
                barBorderRadius: [10, 10, 0, 0],

            }],
            tooltip: {
                show: true,
                trigger: "axis",
                formatter(params) {
                    return comy ? (`${params[0].axisValue}` + '<br>' + `${params[0].value}` + '%') : (`${params[0].axisValue}` + '<br>' + `${params[0].value}`);

                },
                axisPointer: {
                    type: 'shadow',

                },
            },

            grid: {
                left: 45,
                top: 45,
                right: 30,
                bottom: 36,
            }
        }
        myChart.setOption(option);
        that.charts.push(myChart);
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
    //不同颜色折线
    lineCategory: (that, el, series1, series2) => {
        const myChart = echarts.init(document.getElementById(el));
        myChart.setOption({
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['0', '5', '10', '15', '20'],
                axisLabel: {
                    color: '#333'
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#eee",
                        type: "solid"
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#333',
                    show: true
                },
                splitLine: {
                    show: true
                },
                show: true,
                max: 3000,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#fff",
                        type: " ",
                    }
                }
            },
            series: [{
                data: series1,
                type: 'line',
                smooth: true,
                lineStyle: {
                    normal: {
                        width: 3,
                        type: 'dashed',
                        label: {
                            show: true
                        },
                        color: '#B5E5FF',
                        shadowBlur: 20,
                        shadowColor: 'rgba(0,147,255,5)'
                    }
                },
                itemStyle: {
                    normal: {
                        label: {
                            show: false,
                        },
                        color: '#0093FF',
                    }
                },
            }, {
                data: series2,
                type: 'line',
                smooth: true,
                lineStyle: {
                    normal: {
                        width: 3,
                        label: {
                            show: true
                        },
                        color: '#FFBA89',
                        shadowBlur: 20,
                        shadowColor: 'rgba(254,107,3,3)'
                    }
                },
                itemStyle: {
                    normal: {
                        label: {
                            show: false,
                        },
                        color: '#FF6A00',
                    }
                },
            }],
            grid: {
                x: 55,
                y: 10,
                x2: 45,
                y2: 40,
                borderWidth: 1
            },

            tooltip: {
                show: true,
                trigger: 'axis',
                color: 'rgba(102,153,255,.6)',
                padding: [10, 15, 10, 15],
                fontSize: '14px',
                axisPointer: {
                    type: 'shadow'
                }
            }
        });
        that.charts.push(myChart);
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
    // 全国地图
    initMap(that, data) {
        echarts.registerMap("mapCountry", sz);
        const map = echarts.init(document.getElementById("map-container"));
        map.setOption({
            geo: {
                map: "mapCountry"
            },
            visualMap: {
                min: 1000,
                max: 1000000,

            },
            series: [{
                type: 'map',
                map: 'mapCountry',
                mapType: 'CN',
                label: {
                    show: true
                },
                // 地图区域的多边形 图形样式
                itemStyle: {
                    areaColor: '#7B68EE',
                    borderWidth: 0.5,
                    borderColor: '#000',
                    borderType: 'solid'
                },
                // 高亮状态
                emphasis: {
                    label: {
                        show: true,
                        color: '#fff'
                    },
                    itemStyle: {
                        areaColor: '#FF6347'
                    }
                },
                data: data
            }],
            tooltip: {
                trigger: 'item',
                formatter: function (val) {
                    return `  ${val.data.name}  <br>学校总数:  ${val.data.xx}所<br>学生总数:  ${val.data.value}人<br>关注干预学生:  ${val.data.gy}人<br>心理行为问题学生占比:  <span style="color:#e2dd3a">${val.data.zb}%</span> <br><br>突发事件：<span style="color:#e2dd3a">${val.data.tf}</span>次`
                }
            },

        });
        // 定时显示提示框和高亮效果
        let index = -1
        setInterval(function () {
            // 隐藏提示框
            map.dispatchAction({
                type: 'hideTip',
                seriesIndex: 0,
                dataIndex: index
            })
            // 显示提示框
            map.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: index + 1
            })
            // 取消高亮指定的数据图形
            map.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
            })
            // 高亮指定的数据图形
            map.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: index + 1
            })
            if (index == 0) {
                map.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: 9
                })
            }
            index++
            if (index > 8) {
                index = 0
            }
        }, 2000)

        that.charts.push(map);
        window.addEventListener('resize', () => {
            map.resize();
        })
    },
    // 旧版本高德
    area() {
        var map = new AMap.Map('map-container', {
            resizeEnable: true,
            zoom: 13,
            center: [113.94168366406248, 22.542299426249087],
            layers: [
                new AMap.TileLayer.RoadNet({
                    zIndex: 20
                }),
                new AMap.TileLayer({
                    zIndex: 6,
                    opacity: 1,
                })
            ]
        });
        var lnglats = [
            [113.91765107128904, 22.543072335306334],
            [113.92069269631193, 22.492982852443028],
            [113.92552067253874, 22.55710285485857],
            [113.96890272114561, 22.529060209578752],
            [113.93215109322355, 22.480888885182196],
            [113.89723409626768, 22.504421583195004],
            [113.92888416264341, 22.497408782030007],
            [113.93560577843473, 22.496184602906048],
            [113.98670186016844, 22.538494272415637],
            [113.92641116593168, 22.544122691964095],
            [113.90495349381254, 22.513297346717096],
            [113.95130743000792, 22.523584788306213],
            [113.97759307835386, 22.561323621795676],
            [113.93522490475462, 22.515314260980624],
            [113.94297112438963, 22.499103781398574]

        ]
        var marker
        var name = [
            '南头中学',
            '蛇口育才教育集团育才中学',
            '博伦职业技术学校',
            '华侨城中学深圳湾部',
            '南山外国语学校（集团）滨海中学',
            '北京师范大学南山附属学校中学部',
            '蛇口育才教育集团育才二中',
            '蛇口育才教育集团育才三中',
            '华侨城中学侨城初中部',
            '南山实验教育集团麒麟中学',
            '南山实验教育集团南海中学',
            '南山外语学校（集团）高新中学',
            '南山外国语学校（集团）桃源中学',
            '南山区第二外国语学校（集团）学府中学',
            '南山外国语学校（集团）高级中学',
        ]
        for (var i = 0; i < lnglats.length; i++) {
            marker = new AMap.Marker({
                position: lnglats[i],
                title: `${name[i]}\n学校总数：${Math.ceil(Math.random() * 10000 )}人\n特别关注人数：${Math.ceil(Math.random() * 30 )}人\n心理行为问题学校占比${(Math.random() * 5).toFixed(2)}%`,
                map: map
            })
            // 自定义点标记内容
            var markerContent = document.createElement("div");

            // 点标记中的图标
            var markerImg = document.createElement("img");
            markerImg.style = " width: 25px;height: 34px;";
            markerImg.src = "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png";
            markerContent.appendChild(markerImg);
            marker.setContent(markerContent); //更新点标记内容
        }
        map.setFitView()
        AMap.plugin('AMap.DistrictSearch', function () {
            new AMap.DistrictSearch({
                extensions: 'all',
                subdistrict: 0
            }).search('南山区', function (status, result) {
                // 外多边形坐标数组和内多边形坐标数组
                var outer = [
                    new AMap.LngLat(-360, 90, true),
                    new AMap.LngLat(-360, -90, true),
                    new AMap.LngLat(360, -90, true),
                    new AMap.LngLat(360, 90, true),
                ];
                var holes = result.districtList[1].boundaries
                var pathArray = [
                    outer
                ];
                pathArray.push.apply(pathArray, holes)
                var polygon = new AMap.Polygon({
                    pathL: pathArray,
                    strokeColor: '#04082b',
                    strokeWeight: 1,
                    fillColor: '#04082b',
                    fillOpacity: 1
                });
                polygon.setPath(pathArray);
                map.add(polygon)
            })
        })
    },
    // 新版本高德
    newArea(id, name, index, that) {
        // let that = this
        let map = new AMap.Map(id, {
            resizeEnable: true, //是否监控地图容器尺寸变化
            mapStyle: "amap://styles/darkblue",
            zoom: 13,
            center: [113.94168366406248, 22.542299426249087],
        });

        let marker
        for (let i = 0; i < that.lnglats.length; i++) {
            marker = new AMap.Marker({
                position: that.lnglats[i],
                map: map
            })
            // 自定义点标记内容
            let markerContent = document.createElement("div");
            // 点标记中的图标
            let markerImg = document.createElement("img");
            markerImg.className = i % 5 == 0 ? "marker_img s1" : i % 6 == 0 ? "marker_img s2" :
                "marker_img map_icon";
            markerImg.style = i % 5 == 0 ? "width: 58px;height: 58px;position: relative;" : i % 6 == 0 ? "width: 48px;height: 48px;position: relative;" : " width: 38px;height: 38px;position: relative;";
            markerImg.src = i % 5 == 0 ? that.imgList[1] : i % 6 == 0 ? that.imgList[2] : that.imgList[0];
            markerContent.appendChild(markerImg);
            marker.setContent(markerContent); //更新点标记内容
            (function (markerImg, i) {
                markerImg.onmouseover = (e) => {
                    that.thisIndex = i
                    let mapTitle = document.getElementsByClassName("map_title");
                    mapTitle[0].style.display = "block";
                    mapTitle[0].style.left = e.x + 20 + "px"
                    mapTitle[0].style.top = e.y + "px"
                }
            })(markerImg, i);
            (function (markerImg) {
                markerImg.onmouseout = () => {
                    let mapTitle = document.getElementsByClassName("map_title");
                    mapTitle[0].style.display = "none";
                }
            })(markerImg);


        }

        map.setFitView()
        AMap.plugin('AMap.DistrictSearch', function () {
            new AMap.DistrictSearch({
                extensions: 'all',
                subdistrict: 0
            }).search(name, function (status, result) {
                // 外多边形坐标数组和内多边形坐标数组
                let outer = [
                    new AMap.LngLat(-360, 90, true),
                    new AMap.LngLat(-360, -90, true),
                    new AMap.LngLat(360, -90, true),
                    new AMap.LngLat(360, 90, true),
                ];
                let holes = result.districtList[index].boundaries
                let pathArray = [
                    outer
                ];
                pathArray.push.apply(pathArray, holes)
                let polygon = new AMap.Polygon({
                    pathL: pathArray,
                    strokeColor: '#04082b',
                    strokeWeight: 1,
                    fillColor: '#155292',
                    fillOpacity: 0.5
                });
                polygon.setPath(pathArray);
                map.add(polygon)
            })
        })
    },
    // 饼图
    draw(id, data, color, radius) {
        let myChart = echarts.init(document.getElementById(id));
        myChart.setOption({
            tooltip: {
                trigger: "item",
                backgroundColor: 'rgba(102,153,255,.6)',
                axisPointer: {
                    type: "shadow",
                    animation: false
                },
                formatter: function (params) {
                    let obj = params
                    return `${params.seriesName}<br>${params.name} : ${params.value}`
                }
            },
            legend: {
                orient: 'vertical',
                right: 'right',
                y: 'center',
                textStyle: { //图例文字的样式
                    color: '#fff',
                    fontSize: 12
                },
                icon: "circle",
                itemWidth: 10,
                itemHeight: 10,
                padding: [0, 10, 0, 0],
                itemGap: 14
            },
            color: color,
            series: [{
                name: '访问来源',
                type: 'pie',
                radius: radius,
                center: ['38%', '50%'],
                startAngle: 350,
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label: { //饼图图形上的文本标签
                    normal: {
                        show: true,
                        position: '', //标签的位置
                        textStyle: {
                            fontWeight: 300,
                            fontSize: 14, //文字的字体大小
                            color: 'white',
                            fontFamily: 'din'
                        },
                        formatter: '{d}%'
                    }
                },
                labelLine: {
                    normal: {
                        length: 5
                    }
                },
            }],
            grid: {
                left: 50,
                top: 25,
                right: 20,
                bottom: 5,
            }
        });
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
    //自定义样式 柱状图
    showNewColumCategory(that, el, source, series) {
        const myChart = echarts.init(document.getElementById(el));
        let option = {
            tooltip: {
                backgroundColor: 'rgba(102,153,255,.6)',
            },
            dataset: {
                source: source
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    textStyle: {
                        color: '#fff', //坐标值得具体的颜色                   
                    }
                }
            },
            yAxis: {
                show: false
            },
            series: series,
            grid: {
                left: 10,
                top: 5,
                right: 10,
                bottom: 26,
            }
        }
        myChart.setOption(option);
        that.charts.push(myChart);
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
    //多曲线
    multiCurve(that, el, xAxis, series) {
        const myChart = echarts.init(document.getElementById(el));
        let option = {
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xAxis,
                axisLabel: {
                    textStyle: {
                        color: '#fff', //坐标值得具体的颜色                   
                    }
                }
            },
            yAxis: {
                type: 'value',
                max: 900,
                min: 0,
                splitNumber: 6,
                boundaryGap: [0, "100%"],
                axisLabel: {
                    textStyle: {
                        color: '#fff', //坐标值得具体的颜色                   
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#294a88',
                        width: 1,
                        type: 'dashed'
                    }
                },
            },
            tooltip: {
                show: true,
                trigger: "axis",
                backgroundColor: 'rgba(102,153,255,.6)',
                padding: [10, 15, 10, 15],
                fontSize: '14px',
                axisPointer: {
                    type: 'shadow',

                },
                textStyle: {
                    align: 'left'
                }
            },
            series: series,
            grid: {
                left: 40,
                top: 15,
                right: 20,
                bottom: 26,
            }
        }
        myChart.setOption(option);
        that.charts.push(myChart);
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
    // 弧形折线图
    showInitCategory(that, el, xAxis, data, dataEcharts) {
        const myChart = echarts.init(document.getElementById(el));
        let option = {}
        option = {
            xAxis: {
                type: 'category',
                axisLine: {
                    show: false,
                },
                data: xAxis,
                axisLabel: {
                    interval: 0,
                    rotate: 0,
                    color: "#fff",
                }
            },
            yAxis: {
                type: "value",
                max: x => Math.round((x.max + (x.max - x.min) * 0.2) * 1000) / 1000,
                min: 0,
                boundaryGap: [0, "100%"],
                show: false
            },
            tooltip: {
                show: true,
                trigger: "axis",
                formatter(params) {
                    let obj = params[0]
                    let v = obj.value
                    return (obj.axisValue + '份<br>' + "特别关注学生总人数: " + v + '人<br>' + "环比+ " + dataEcharts[obj.dataIndex].num1 +
                        '%<br>' + "新增发现: " + dataEcharts[obj.dataIndex].num2 + '人<br>' + "解除关注学生人数: " + dataEcharts[obj.dataIndex].num3 + '人<br>')

                },
                backgroundColor: 'rgba(102,153,255,.6)',
                padding: [10, 15, 10, 15],
                fontSize: '14px',
                axisPointer: {
                    type: 'shadow',

                },
                textStyle: {
                    align: 'left'
                }
            },
            series: [{
                data: data,
                type: "line",
                smooth: true, //弧形
                itemStyle: {
                    normal: {
                        color: "#6699FFFF",
                        label: {
                            show: false,

                        }
                    }
                },
                areaStyle: {
                    color: "rgb(102,153,255,0.1)"
                }
            }],
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
            }
        };
        let index = -1
        setInterval(function () {
            // 隐藏提示框
            myChart.dispatchAction({
                type: 'hideTip',
                seriesIndex: 0,
                dataIndex: index
            })
            // 显示提示框
            myChart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: index + 1
            })
            // 取消高亮指定的数据图形
            myChart.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
            })
            // 高亮指定的数据图形
            myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: index + 1
            })
            if (index == 0) {
                myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: 9
                })
            }
            index++
            if (index > 13) {
                index = -1
            }
        }, 2000)

        myChart.setOption(option);
        that.charts.push(myChart);
        window.addEventListener('resize', () => {
            myChart.resize();
        })
    },
}

export default echart