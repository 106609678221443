<template>
  <div class="TopFour">
    <el-row :gutter="10">
      <el-col :xs="4" :sm="9"  >
        <el-card style="height:276px;" class="bgCard1">
            <h3 style="height:40px;">咨询预约</h3>
            <el-row  :gutter="24">
            <el-col :xs="24" :sm="8" v-for="(item, index) in cardList" class="left1" :key="index"  :class="'card card'+ index">
                 <div class="name">{{item.name}}</div>
                 <div class="cs"><span  class="num">{{item.num}} </span>次</div>
              </el-col>
            </el-row>
        </el-card>
      </el-col>
      <el-col :xs="4" :sm="7" >
        <el-card>
          <h3 style="height:30px;">用户量</h3> 
          <div class="all flex">
            <div class="left flex">
              <img src="../../../assets/images/home/son.png" style="width:70px;height:70px"/>
              <div class="text">
                <div class="text_name">用户总量</div>
                <div class="text_num">16,981</div>
              </div>
            </div>
            <div class="right">
               <pieBar class="pieBar"/>  
            </div>
          </div>
        </el-card>
      </el-col>
       <el-col :xs="4" :sm="8" >
        <el-card >
           <h3 style="height:30px;">访客属性-年龄分布</h3>
            <div id="chart1" class="charts" style="width: 100%; height: 220px;margin-top:-15px;">></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    findSum,
    indexTop,
    indexNum
  } from "../../../api/api";
  import echart from "../../../common/echarts"
  import pieBar from '../components/pieBar'
  export default {
    components: {
      pieBar
    },
    data() {
      return {
        charts:[],
        cardList:[{num:"808", name:'心理咨询师'},
                  {num:"122,251", name:'累计咨询量'},
                  {num:"356,586", name:'视频'},
                  {num:"45,864", name:'语音'},
                  {num:"19,801", name:'线下'},],
        colorList: [{color:"#5EDEC6",value:'27'},{color:"#FF7BA1",value:'8'},{color:"#8CDFFF",value:'12'},{color:"#FFC96A",value:'43'},{color:"#84A8FF",value:'23'},{color:"#BC8EE3",value:'28'}],
        source: ['18岁以下', '18-24岁', '25-34岁', '35-44岁', '45-54岁','55岁以上'],
       
      };
    },

    created() {
    },
    mounted() {
      setTimeout(() => {
      this.getCategory()

         }, 1500)
    },
    methods: {
       getCategory() {
                let series = []
                  series = this.colorList.map(val => {
                    return {
                        value: val.value,
                        itemStyle: {
                            color: val.color,
                            barBorderRadius: [4,4,0,0]
                        }
                    }
                })
                echart.columCategory(
                    this,
                    "chart1",
                    this.source,
                    series,
                    "0"
                )
            },
    },
  };
</script>

<style lang="less" scoped>
  .TopFour {
      z-index: 10;
    .left1{
      transition: transform 0.6s;
        &:hover{
        transform: scale(1.15);
        z-index: 99999;
      }
    }
    .bgCard1{
      width: 100%;
      position: relative;
    }
    .card{
      width: 31%;
      // max-width: 168px;
      // min-width: 128px;
      height: 84px;
      background-repeat: no-repeat;
      // background-position: 50% 50%;
      // background-size: contain;
      margin-left:17px;
      color: #FFFFFF;
      
      .name{
        padding-top:10px;
      }
      .cs{
        margin-top: 10px;
      }
      .num{
        font-size: 22px;
        padding-top:30px;
      }
     
    }
    .card0{
      background-image:url(../../../assets/images/home/bg1.png);
      // margin-bottom:20px;
      position: absolute;
      top:0;
      left: 0;
      
    }
    .card1{
      background-image:url(../../../assets/images/home/bg2.png) ;
      // margin-bottom:20px;
      position: absolute;
      top:0;
      left: 33%;

    }
    .card2{
      background-image:url(../../../assets/images/home/bg3.png) ;
      position: absolute;
      top:0;
      right: 0;

    }
    .card3{
      background-image:url(../../../assets/images/home/bg4.png) ;
      position: absolute;
      top:100px;
      left: 0;

    }
    .card4{
      background-image:url(../../../assets/images/home/bg5.png) ;
      position: absolute;
      top:100px;
      left: 33%;

    }
    .all{
       width:100%;
        .left{
       width:37%;
       margin-top:40px;
        .text{
          margin: 0 10px;
          color:#333333 ;
          height:70px;
          line-height: 32px;
          .text_num{
            font-size:24px;
          }
        }
      }
      .right{
         width:63%;
        .pieBar{
          width:240px;
          min-width:140px;
          margin-top:-24px;
          // margin-left:-20px;

        }
      }

    }
 
  }
</style>