<template>
  <div id="charts" style="width:100%;height:100%;">
    
  </div>
</template>
<script>
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
//下面的是需要的提示框
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
export default {
  data(){
    return {
      data:[{
        spScore:1000,
      },{
        spScore:2500,
      
      },{
        spScore:1500,
      },{
        spScore:1100,
      },{
        spScore:2100,
        
      }],
      data2:[{
        spScore:1500,
      },{
        spScore:2800,
      
      },{
        spScore:1000,
      },{
        spScore:2600,
      },{
        spScore:1600,
        
      }],
      attrs:[],
      attrs2:[]
    }
  },
  created(){
    this.attr();
    
  },
  mounted() {
      this.draw ('charts');	
      this.data = this.dataC	
      this.data2 = this.dataB //调用
	},
	methods:{
    attr(){
      var atr = [];
      var arr = [];
      this.data.forEach(item=>{
        atr.push(item.spScore);
      })
      this.data2.forEach(item=>{
        arr.push(item.spScore);
      })
      this.attrs = atr;
      this.attrs2 = arr;

    },
    draw (id) {
      var that = this;
      let myChart = echarts.init(document.getElementById(id));
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['0', '5', '10', '15', '20'],
          axisLabel:{color:'#333'},
          axisLine: {
              show: true,
              lineStyle: {
                  color: "#eee",
                  type: "solid"
              }
          }
        },
        yAxis: {
          type: 'value',axisLabel:{color:'#333',show:true},splitLine:{show: true},show:true,
           max: 3000,
          axisLine: {
              show: true,
              lineStyle: {
                  color: "#fff",
                  type: " ",
              }
          }
        },
        series: [{
            data: this.attrs,
            type: 'line',
            smooth: true,
            lineStyle:{
              normal:{
                width:3,
                type:'dashed',
                label:{show:true},
                 color:'#B5E5FF',
                  shadowBlur: 10,
                  shadowColor: 'rgba(0,147,255,5)'
              }
            },
            itemStyle: {
              normal: {
                label : {show: false,},
                 color:'#0093FF',
              }
            },
        },{
            data: this.attrs2,
            type: 'line',
            smooth: true,
            lineStyle:{
              normal:{
                width:1,
                label:{show:true},
                color:'#FFBA89',
                  shadowBlur: 1,
                  shadowColor: 'rgba(254,107,3,3)'
              }
            },
            itemStyle: {
              normal: {label : {show: false,},
                      color:'#FF6A00',
                      }
            },
        }],
        grid:{
          x:55,
          y:10,
          x2:45,
          y2:40,
          borderWidth:1
        },
        
        tooltip:{
          show:true,
          trigger:'axis',
          // formatter(params){
          //   return params[0].name+'份<br>'+that.data[params[0].dataIndex].spScore+'人<br>'
          // },
          color:'rgba(102,153,255,.6)',
          padding:[10,15,10,15],
          fontSize:'14px',
          axisPointer:{
            type:'shadow'
          }
        }
      });
    },
	}
}
</script>