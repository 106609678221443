<template>
  <div id="charts2" style="width：100%;height:230px;min-height:140px;">
    
  </div>
</template>
<script>
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/gauge')
//下面的是需要的提示框
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
export default {
  mounted() {
      this.draw ('charts2');		//调用
	},
	methods:{
    draw (id) {
      let myChart = echarts.init(document.getElementById(id));
      myChart.setOption({
        tooltip: {
            trigger: 'item'
        },
      
        // legend: {
        //       top: '85%',
        //       left: 'top',
        //     orient: 'vertical',
        //     right: 'right',
        //     y:'center',
        //     textStyle: { //图例文字的样式
        //         color: '#fff',
        //         fontSize: 12
        //     },
        //     icon: "circle",
        //     itemWidth:10,
        //     itemHeight:10,
        //     padding:[0,10,0,0],
        //     itemGap: 14
            
        // },
        grid:{
          x:55,
          y:10,
          x2:45,
          y2:80,
          borderWidth:1
        },
         series: [
        {
            name: '',
            type: 'pie',
            radius: ['26%', '41%'],
            avoidLabelOverlap: true,
            
            label: {
                show: true,
                position: 'center',
                
            },
            emphasis: {
                
                label: {
                    show: true,
                     formatter(params){
                        //  console.log(params,"params")
                        return params.data.name+ " "+ params.data.value
                    },
                    // fontSize: '14',
                    fontWeight: 'bold'
                }
            },
            itemStyle: {
            normal:{
                color: function(params) {
                                     var colorList = [
                                    {
                                        c1: ' #00E4AF',  //男
                                        c2: '#00B7EC'
                                                                                                   
                                    },
                                    {
                                       c1: '#2CBAF1',//未知
                                       c2: '#5E77FF'   
                                    },
                                    {
                                        c1: ' #FAB2FB',  //女
                                        c2: '#7D7DFF'     
                                      
                                    }]
                                     return new echarts.graphic.LinearGradient(1, 0, 0, 0, [{ //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上

                                            offset: 0,
                                            color: colorList[params.dataIndex].c1
                                        }, {
                                            offset: 1,
                                            color: colorList[params.dataIndex].c2
                                        }]) 
                          }          
                }            
            },
              label:{            //饼图图形上的文本标签
                normal:{
                    show:true,
                    position:'', //标签的位置
                    textStyle : {
                        fontWeight : 300 ,
                        fontSize : 12 ,   //文字的字体大小
                        // color:['#FAB2FB'],
                        fontFamily:'din'
                    },
                    formatter(params){
                        return params.data.name+ " "+ params.data.value
                    },
                                  
                }
              },
            labelLine: {
                show: true
            },
            data: [
                {value: 2972, name: '男'},
                {value: 6865, name: '未知'},
                {value: 3879, name: '女'},
            ]
        }
        ]
      });
    },
	}
}
</script>