<template>
  <div class="topTwo">
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24">
        <el-card>
            <h3 style="height:30px;">趋势图</h3>
          <div class="flex_just_s_between menu" >
              <div>
                <span class="span"  v-for="item in wpList" :key="item.name" :class="{active : active == item.id}"  @click="selected(item)">{{item.name}}</span>
              </div>
              <div>
                <div class="text_right flex" >
                  <div class="check" ></div>今日
                  <div class="check"  style="background: #65BDFF;"></div>对比
                </div>
                <div>
                    对比：<el-checkbox v-model="checked1"  :disabled="checked2==true">前一日</el-checkbox>
                         <el-checkbox v-model="checked2" :disabled="checked1==true">上周同期</el-checkbox>
                </div>
            </div>
          </div>
          <div  id="line1" style="width: 100%; height: 240px;margin-top:30px;">
            <LineCharts ref="LineCharts" :dataC="dataC" :dataB="dataB"/>
          </div>
        </el-card>
      </el-col>
      <!-- <el-col :xs="24" :sm="24">
        <el-card>
          <h2>TOP10 浏览量</h2>
          <div class=" right_h ">
            <el-table :data="tableData" style="width: 100%" class="table table_h " :height="400">
              <el-table-column type="index" label="排行" width="50" align="center">
                <template slot-scope="scope">
                  <span class="rnking_num" :class="scope.$index < 3?'blue':'grey'">{{scope.$index+1}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="articleText" label="科普文章" min-width="200" align="center">
                <template slot-scope="scope">
                  <div class="text_left">
                    {{scope.row.articleText}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="articleNum" label=" " min-width="90"></el-table-column>
              <el-table-column prop="videoText" label="科普视频" min-width="120" align="center"></el-table-column>
              <el-table-column prop="videoNum" label=" " min-width="90"></el-table-column>
              <el-table-column prop="mainName" label="心理量表" min-width="200" align="center"></el-table-column>
              <el-table-column prop="mainNum" label=" " min-width="90"></el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
  import {
    findSum,
    indexTop,
    indexNum
  } from "../../api/api";
  import LineCharts from "./components/LineCharts"
  import echart from "../../common/echarts"
  export default {
    components: {
      LineCharts,
    },
    data() {
      return {
        charts:[],
        active:"1",
        checked1:"",
        checked2:"",
        dataC:['1000','1500','2500','1100','2100'],
        dataB:['1500','2800','1000','2600','1500'],
        wpList:[{id:"1", name:'浏览量'},
                {id:"2", name:'访客量'},
                {id:"3", name:'IP数'},
                {id:"4", name:'跳出率'},
                {id:"5", name:'平均访问时长'},
                {id:"6", name:'转换次数'}],
        tableData: [],
        getFindSumObj: null,
      };
    },

    created() {
    },
    mounted() {
      this.zhexian()    
    },
    methods: {
      zhexian(){
        echart.lineCategory(
                this,
                "line1",
               this.dataC,
               this.dataB
              );
      },
      // 概览模块
      getFindSum() {
        this.$request(findSum).then((res) => {
          if (res.code === "200") {
            this.getFindSumObj = res.data;
          }
        });
      },
      selected(item){
           this.active = item.id;
           
           if(item.id==2){
             this.dataC = ['1001','2600','1400','2500','1500']
             this.dataB = ['1500','1200','2800','1500','1633']
             this.zhexian()
             
           }else if(item.id==3){
             this.dataC = ['1000','300','1400','800','500']
             this.dataB = ['500','800','800','1500','300']
             this.zhexian()
           }else if(item.id==4){
             this.dataC = ['800','300','1400','800','500']
             this.dataB = ['500','800','800','1500','300']
             this.zhexian()
           }else if(item.id==5){
             this.dataC = ['500','1000','2000','300','500']
             this.dataB = ['500','800','800','2600','300']
             this.zhexian()
           }else if(item.id==6){
             this.dataC = ['1000','280','20','25','500']
             this.dataB = ['500','800','800','52','300']
             this.zhexian()
           }else{
            this.dataC = ['1000','1500','2500','1100','2100']
            this.dataB = ['1500','2800','1000','2600','1500']
            this.zhexian()
           }
      },
      //  前十
      getIndexTopList() {
        this.$request(indexTop).then((res) => {
          if (res.code === "200") {
            // newsTextVos 是文章   newsMp4Vos 是视频   mainVos 是量表
            let article = res.data.newsTextVos || [];
            let video = res.data.newsMp4Vos || [];
            let table = res.data.mainVos || [];
            let articleList = article.map((val, i) => {
              return {
                articleText: val.title,
                articleNum: val.readNum,
              };
            });
            let videoList = video.map((val, i) => {
              return {
                videoText: val.title,
                videoNum: val.readNum,
              };
            });
            // 三种类型数组的长度组成的数组
            let arr = [articleList.length, videoList.length, table.length]
            let newArr = []
            // 找到最长的数组
            let maxLength = Math.max(...arr)
            for (let i = 0; i < maxLength; i++) {
              newArr.push({
                id: i
              })
            }
            this.tableData = newArr.map((val, i) => {
              return {
                ...articleList[i],
                ...videoList[i],
                ...table[i],
              }
            })
          }
        });
      },
      // 最近7天
      getIndexNum() {
        this.$request(indexNum).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            setTimeout(() => {
              this.$util.showInitCategory(
                this,
                "line",
                data.map((val) => {
                  return val.time;
                }),
                data.map((val) => {
                  return val.num;
                })
              );
            }, 500);
            this.$util.resizeCharts(this);
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .topTwo {
      .menu{
          .span{
              margin-left:30px;
              cursor: default;
          }
          .text_right{
            justify-content: flex-end;
            height: 20px;
            line-height: 3px;
            margin-top: -20px;
            .check{
              width: 52px;
              height: 6px;
              background: #FF6A00;
              border-radius: 3px;
              margin: 0 10px;
            }
          }
      }
    .active {
        font-weight: 500;
        border-bottom: 2px solid #5A98FF;
        color: #5A98FF;
       
        }
  }
</style>