<template>
  <div class="home_modal">
    <div class="border" :style="{background:obj.background}">
      <div class="name">{{ obj.title }}</div>
      <div class="num">{{ obj.num }}<span>/{{obj.id=='modal1'?'人':obj.id=='modal2'?'人次':obj.id=='modal5'?'人次':obj.id=='modal6'?'条':'次'}}</span></div>
      <div v-if="!obj.icon" :id="obj.id" class="charts"></div>
      <!-- <div class="text">日访问量 <span>1,234</span></div> -->
      <div class="img"><img :src="obj.icon" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      charts: [],
    };
  },
  props: ["obj"],
  mounted() {
    let that = this;
    setTimeout(() => {
      this.$util.initCategory(
        that,
        that.obj.id,
        [1, 15, 17, 77, 12, 15, 77, 55,22,44,10,10,22,33,33,77,11,44,11,10,10,10,20],
        that.obj.color1,
        that.obj.color2
      );
    }, 500);
    this.resizeCharts(this);
  },
  methods: {
      resizeCharts(that, ) {
    for (let i = 0; i < that.charts.length; i++) {
      const chart = that.charts[i];
      chart.resize();
    }
  },
  },
};
</script>

<style lang="less">
.home_modal {
  .border {
    height: 150px;
    border-radius: 2px;
    background: #fff;
    padding: 20px 16px 11px 24px;
    margin-bottom: 10px;
    .name {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.45);
    }
    .num {
      // margin-bottom: 17px;
      font-size: 30px;
      color: rgba(0, 0, 0, 0.85);
      span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .charts {
      margin-top: 17px;
      width: 100%;
      height: 30px;
    }
    .text {
      padding-top: 9px;
      color: rgba(0, 0, 0, 0.65);
      // border-top: 1px solid #e8e8e8;
      // margin-top: 20px;
      span {
        color: rgba(0, 0, 0, 0.85);
        margin-left: 8px;
      }
    }
    .img{
      text-align: right;
      img{
        // width: 70px;
      }
    }
  }
}
</style>
