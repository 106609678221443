<template>
  <div class="TopThree">
    <el-row :gutter="10">
      <el-col :xs="4" :sm="6" >
        <el-card>
            <h3 style="height:30px;">TOP5科普文章阅读量</h3><span class="float">阅读量：次</span>
             <div id="chart" class="charts" style="width: 100%; height: 226px;margin-top:-5px;">></div>
            <!-- <Bar/> -->
        </el-card>
      </el-col>
      <el-col :xs="4" :sm="6"  >
        <el-card>
          <h3 style="height:30px;">量表测评</h3>
           <div id="line" style="width: 100%; height: 226px;margin-top:-5px;"></div>
        </el-card>
      </el-col>
       <el-col :xs="4" :sm="7" >
        <el-card>
           <h3 style="height:30px;">TOP10量表测评</h3><span class="float">测评量：人次</span>
           <div class="platform_con">
		                <div v-for="(item,index) in severeList" :key="index" class="platform_con_item">
		                    <div class="name">{{item.mainName}}</div>
		                    <div class="jdt"><div class="jdtk"><div class="jdt_item" :style="{width:item.mainNum/severeListAll*100+'%'}"></div></div></div>
		                    <div class="score">{{item.mainNum | numTab}}</div>
		                </div>
		        </div>
        </el-card>
      </el-col>
       <el-col :xs="4" :sm="5" >
        <el-card >
           <h3 style="height:30px;">TOP10搜索词</h3>
           <vue-wordcloud style="width: 100%;height:220px;" id="canvas"
                :options="options" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    findSum,
    indexTop,
    indexNum
  } from "../../../api/api";
  import LineCharts from "../components/LineCharts"
  import echart from "../../../common/echarts"
  export default {
    components: {
      LineCharts,
      
    },
    data() {
      return {
        charts:[],
        active:"1",
        checked1:"",
        checked2:"",
        wpList:[{id:"1", name:'浏览量'},
                {id:"2", name:'访客量'},
                {id:"3", name:'IP数'},
                {id:"4", name:'跳出率'},
                {id:"5", name:'平均访问时长'},
                {id:"6", name:'转换次数'}],
              severeList:[
                {
		                name:'西安心理',
		                score:2123
		            },{
		                name:'父母关系',
		                score:1723
		            },{
		                name:'西安心理',
		                score:1623
		            },{
		                name:'西安心理',
		                score:1123
		            },{
		                name:'西安心理',
		                score:923
		            },{
		                name:'西安心理',
		                score:823
		            },{
		                name:'西安心理',
		                score:723
		            },{
		                name:'西安心理',
		                score:523
		            },{
		                name:'其他焦虑',
		                score:123
		            }
        		],
        		severeLists:0,
        		severeListAll:0,
        		//当前重症精神病人数
        		alls:14245,
        		sex:[6602,7643],
        tableData: [],
        getFindSumObj: null,
        colorList: [{color:"#5EDEC6",value:2000},{color:"#FF7BA1",value:1600},{color:"#8CDFFF",value:2300},{color:"#FFC96A",value:1900},{color:"#84A8FF",value:1200}],
        nameList: ["product", "福田区", "罗湖区", "南山区", "盐田区"],
        source: ['心理测试', '面对抑郁', '热爱生活', '热爱生活', '热爱生活'],
        defaultWords: [{
                        "name": "克服恐惧",
                        "value": 28
                    },
                    {
                        "name": "人格测评",
                        "value": 18
                    },
                    {
                        "name": "学习",
                        "value": 14
                    },
                    {
                        "name": "睡眠",
                        "value": 16
                    },
                    {
                        "name": "感情家庭",
                        "value": 12
                    },
                    {
                        "name": "抑郁",
                        "value": 9
                    },
                    {
                        "name": "调节情绪",
                        "value": 17
                    },
                    {
                        "name": "受人欢迎",
                        "value": 16
                    },
                    {
                        "name": "网瘾",
                        "value": 10
                    }, {
                        "name": "同学关系",
                        "value": 16
                    },
                    {
                        "name": "自闭",
                        "value": 15
                    },
                    {
                        "name": "提升自信",
                        "value": 13
                    },
                    {
                        "name": "活动讲座",
                        "value": 9
                    }
                ],
         options: {
                    list: [],
                    color: [
                        ["#16ebf0"],
                        ["#ffde39"],
                        ["#51ef9f"],
                        ["#ea6228"],
                        ["#327fff"],
                        ["#ffe88e"],
                        ["#bbeb7b"],
                        ["#68ecc6"],
                        ["#6bc5e9"],
                        ["#fa8e58"],
                        ["#4630ec"],
                        ["#ed6b65"],
                        ["#8eadda"]
                    ],
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    fontFamily: "微软雅黑",
                    minFontSize: 12,
                    maxFontSize:25
                }
                
      };
    },

    created() {
        this.options.list = this.defaultWords.map(item => {
                return [item.name, item.value]
            })
            this.options.tooltip = this.defaultWords.map(item => {
                return {
                    show: true
                }
            })
      this.getIndexNum();
      this.getIndexTopList()
    },
    mounted() {
      setTimeout(() => {
      this.getCategory()

         }, 1000)
    },
       filters:{
			numTab(val){
				return val.toString().length>3?val.toLocaleString():val;
			}
		},
    methods: {
   
       getCategory() {
                let series = []
                series = this.colorList.map(val => {
                    return {
                        value: val.value,
                        itemStyle: {
                            color: val.color,
                            barBorderRadius: [4,4,0,0]
                        }
                    }
                })
                echart.columCategory(
                    this,
                    "chart",
                    this.source,
                    series
                )
            },
      // 概览模块
      getFindSum() {
        this.$request(findSum).then((res) => {
          if (res.code === "200") {
            this.getFindSumObj = res.data;
          }
        });
      },
      selected(item){
           this.active = item.id;
      },
      //  前十
      getIndexTopList() {
        this.$request(indexTop).then((res) => {
          if (res.code === "200") {
            // newsTextVos 是文章   newsMp4Vos 是视频   mainVos 是量表
            let article = res.data.newsTextVos || [];
            let video = res.data.newsMp4Vos || [];
            let table = res.data.mainVos || [];
            let arr1 = []
            this.severeList = table.map((item)=>{
              let num  = JSON.parse(item.mainNum)
                this.severeLists+= num;
                arr1.push(num);
                this.severeListAll = Math.max(...arr1);
                return {
                  mainName: item.mainName,
                  mainNum: num,
                };
            })
            let articleList = article.map((val, i) => {
              return {
                articleText: val.title,
                articleNum: val.readNum,
              };
            });
            let videoList = video.map((val, i) => {
              return {
                videoText: val.title,
                videoNum: val.readNum,
              };
            });
            // 三种类型数组的长度组成的数组
            let arr = [articleList.length, videoList.length, table.length]
            let newArr = []
            // 找到最长的数组
            let maxLength = Math.max(...arr)
            for (let i = 0; i < maxLength; i++) {
              newArr.push({
                id: i
              })
            }
            this.tableData = newArr.map((val, i) => {
              return {
                ...articleList[i],
                ...videoList[i],
                ...table[i],
              }
            })
          }
        });
      },
      // 最近7天
      getIndexNum() {
        this.$request(indexNum).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            setTimeout(() => {
              this.$util.showInitCategory(
                this,
                "line",
                data.map((val) => {
                  return val.time;
                }),
                data.map((val) => {
                  return val.num;
                }),
                '#0068cd',
                '#8cc3fbc9',
                 true,
                  0,
                  '',
                  '0'
              );
            }, 500);
            this.$util.resizeCharts(this);
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .TopThree {
     z-index: 10;
    .left1{
      transition: transform 0.6s;
      z-index: 900;
        &:hover{
        transform: scale(1.15);
        z-index: 99999;
      }
    }
    .float{
      float:right;
      color:#999;
      margin-top:-25px;
      font-size: 12px;

    }
    .platform_con{
			            width:100%;
			            margin:0 auto;
			            display: flex;
			            flex-direction: column;
			            justify-content: space-between;
			            .platform_con_item{
			                width:100%;
			                height:22px;
			                display: flex;
			                justify-content: space-between;
			                .name{
			                    width:36%;
			                    text-align:right;
                          font-size:12px;
                          overflow: hidden;
                          text-overflow:ellipsis;
                          white-space: nowrap;
			                }
			                .jdt{
		                      // margin-left:10px;
		                      border-radius:10px;
			                    width:46%;
			                    height:12px;
			                    margin-top:3px;
			                    background:rgba(41, 44, 51, 0.1);
			                    position:relative;
			                    .jdtk{
			                    	width:calc(100% - 55px);
			                      height:100%;
			                    }
			                    .jdt_item{
			                        border-radius:10px;
                              height:100%;
                              background: linear-gradient(90deg, #8AC0F6 0%, #96B6FF 100%);
			                    }
		                        span{
		                        	position:absolute;
		                        	right:5px;
		                        	top:0;
		                        	line-height:12px;
		                        	font-size:10px;
                              width:50px;
                              text-align:right;
		                        }
			                }
			                .score{
			                    width:10%;
			                    margin-right:5%;
			                    text-align:right;
			                    font-size:12px;
			                    color:#666		            
;
			                }
			            }
              }
  }
</style>