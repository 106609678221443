<template>
  <div class="Footer">
    <el-row :gutter="10">
      <el-col :xs="4" :sm="8" style="margin-top:10px;" >
        <el-card style="height:400px;" class="bgCard1">
            <h3 style="height:50px;">心理服务机构</h3>
            <el-row  :gutter="24">
            <el-col :xs="24" :sm="12" v-for="(item, index) in cardList" class="left1" :key="index"  :class="'card card'+ index">
                 <div class="name">{{item.name}}</div>
                 <div class="text_center"><span  class="num">{{item.num}} </span>所</div>
              </el-col>
             
            </el-row>
        </el-card>
      </el-col>
      <el-col :xs="4" :sm="8" style="margin-top:10px;" >
        <el-card  style="height:380px;padding-bottom:20px;">
          <h3 style="height:30px;">地域分布</h3> 
          <Maps style="height:500px;" />
        </el-card>
      </el-col>
       <el-col :xs="4" :sm="8" style="margin-top:10px;" >
        <el-card style="height:400px;">
           <h3 style="height:50px;">机构累计咨询量TOP10</h3>
            <div class="platform_con">
		                <div v-for="(item,index) in severeList" :key="index" class="platform_con_item">
		                    <div class="name">{{item.name}}</div>
		                    <div class="jdt"><div class="jdtk"><div class="jdt_item" :style="{width:item.score/severeListAll*100+'%'}"></div></div></div>
		                    <div class="score">{{item.score | numTab}}{{item.comany}}</div>
		                </div>
		        </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    findSum,
    indexTop,
    indexNum
  } from "../../../api/api";
  import Maps from "../components/Maps"
  import echart from "../../../common/echarts"
  import pieBar from '../components/pieBar'
  export default {
    components: {
      pieBar,
      Maps
    },
    data() {
      return {
        charts:[],
        cardList:[{num:"15", name:'街道心理服务站点'},
                  {num:"31", name:'社会心理服务站点'},
                  {num:"45", name:'社康服务站点'},
                  {num:"32", name:'其他机构站点'},
                  ],
              severeList:[
                {
		                name:'深圳市康宁医院',
                    score:2123,
                    comany:'人'
		            },{
		                name:'福永人民医院临床心理科',
                    score:1723,
                    comany:'人'
		            },{
		                name:'宝安区妇幼保健院',
                    score:1623,
                    comany:'人'
		            },{
		                name:'龙华区慢性病防治中心',
                    score:1123,
                    comany:'人'
		            },{
		                name:'南山区人民医院',
                    score:923,
                    comany:'人'
		            },{
		                name:'福田慢病防治中心',
                    score:823,
                    comany:'人'
		            },{
		                name:'园岭社康',
                    score:723,
                    comany:'人'
		            },{
		                name:'侨香社康',
                    score:523,
                    comany:'人'
		            },{
		                name:'深圳山青云白心理咨询中心',
                    score:423,
                    comany:'人'
		            },{
		                name:'八卦岭社康',
                    score:123,
                    comany:'人'
		            }
        		],
        		severeLists:0,
        		severeListAll:0,
        		//当前重症精神病人数
        		alls:14245,
        		sex:[6602,7643],
        tableData: [],
        getFindSumObj: null,
        colorList: ["#5EDEC6", "#FF7BA1", "#8CDFFF", "#FFC96A", "#84A8FF"],
        nameList: ["product", "福田区", "罗湖区", "南山区", "盐田区"],
        source: ["心理测试结果", "面对抑郁", "热爱生活", "热爱生活", "热爱生活"],
       
      };
    },

    created() {
    },
      mounted() {
      let arr1 = []
      this.severeList.forEach(item=>{
				this.severeLists+=item.score;
				arr1.push(item.score);
			})
			this.severeListAll = Math.max(...arr1);
    },
       filters:{
			numTab(val){
				return val.toString().length>3?val.toLocaleString():val;
			}
		},
    methods: {
      // 概览模块
      getFindSum() {
        this.$request(findSum).then((res) => {
          if (res.code === "200") {
            this.getFindSumObj = res.data;
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .Footer {
       z-index: 10;
    .left1{
      transition: transform 0.6s;
      z-index: 900;
        &:hover{
        transform: scale(1.05);
        z-index: 99999;
      }
    }
    .bgCard1{
      width: 100%;
      position: relative;
    }
    .card{
      width: 46%;
      min-width: 147px;
      background-repeat: no-repeat;
      height: 144px;
      margin-left:17px;
      color: #333333;
      .name{
        padding-top:10px;
      }
      .text_center{
        margin-top: 30px;
      }
      .num{
        font-size: 26px;
        padding-top:30px;
      }
     
    }
    .card0{
      background-image:url(../../../assets/images/home/card1.png) ;
      // margin-bottom:20px;
      position: absolute;
      top:0;
      left: 0;
      
    }
    .card1{
      background-image:url(../../../assets/images/home/card2.png) ;
      // margin-bottom:20px;
      position: absolute;
      top:0;
      right: 0;

    }
    .card2{
      background-image:url(../../../assets/images/home/card3.png) ;
      // margin-bottom:20px;
      position: absolute;
      top:160px;
      left: 0;

    }
    .card3{
      background-image:url(../../../assets/images/home/card4.png) ;
      // margin-bottom:20px;
      position: absolute;
      top:160px;
      right: 0;

    }
    .platform_con{
			            width:100%;
			            margin:0 auto;
			            display: flex;
			            flex-direction: column;
			            justify-content: space-between;
			            .platform_con_item{
			                width:100%;
			                height:28px;
			                display: flex;
			                justify-content: space-between;
			                .name{
                          width:30%;
                          min-width:150px;
			                    text-align:right;
			                    font-size:12px;
			                }
			                .jdt{
		                      margin-left:20px;
		                      border-radius:10px;
			                    width:53%;
			                    height:12px;
			                    margin-top:3px;
			                    background:rgba(41, 44, 51, 0.1);
			                    position:relative;
			                    .jdtk{
			                    	width:calc(100% - 55px);
			                        height:100%;
			                    }
			                    .jdt_item{
			                        border-radius:10px;
                              height:100%;
                              background: linear-gradient(90deg, #7189E5 0%, #9287E7 100%);
			                    }
		                        span{
		                        	position:absolute;
		                        	right:5px;
		                        	top:0;
		                        	line-height:12px;
		                        	font-size:10px;
	        						width:50px;
	        						text-align:right;
		                        }
			                }
			                .score{
                          width:10%;
                          min-width:50px;
			                    margin-right:5%;
			                    text-align:right;
			                    font-size:12px;
			                    color:#666		            
;
			                }
			            }
              }
     .left{
       margin-top:40px;
        .text{
          margin: 0 10px;
          color:#333333 ;
          height:70px;
          line-height: 32px;
          .text_num{
            font-size:24px;
          }
        }
      }
      .right{
        .pieBar{
          width:240px;
          margin-top:-40px;
        }
      }
  }
</style>