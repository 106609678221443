<template>
  <div class="home">
    <div class="title">
      <span> 首页</span>
    </div>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="12" :md="8" :lg="4">
        <homeModal
          :obj="{title:'用户总量',num:getFindSumObj&&getFindSumObj.userSum||0,id:'modal1',background:'#FAD9EF',icon:require('../../assets/images/home/icon1.png')}">
        </homeModal>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4">
        <homeModal
          :obj="{title:'心理咨询总量',num:getFindSumObj&&getFindSumObj.orderedSum||0,id:'modal2',background:'#D5E8FD',icon:require('../../assets/images/home/icon2.png')}">
        </homeModal>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4">
        <homeModal
          :obj="{title:'科普阅读总量',num:getFindSumObj&&getFindSumObj.news2Sum||0,id:'modal4',background:'#D0D7FB',icon:require('../../assets/images/home/icon3.png')}">
        </homeModal>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4">
        <homeModal
           :obj="{title:'视频观看总量',num:getFindSumObj&&getFindSumObj.news2Sum||0,id:'modal4',background:'#D7F5FA',icon:require('../../assets/images/home/icon4.png')}">
        </homeModal>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4">
        <homeModal
          :obj="{title:'量表测评总量',num:getFindSumObj&&getFindSumObj.evaluateSum||0,id:'modal5',background:'#FFF7EC',icon:require('../../assets/images/home/icon5.png')}">
        </homeModal>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4">
        <homeModal
          :obj="{title:'用户评论总量',num:getFindSumObj&&getFindSumObj.commentSum||0,id:'modal6',background:'#FFDBDF',icon:require('../../assets/images/home/icon6.png')}">
        </homeModal>
      </el-col>
    </el-row>
    <TopTwo />
    <TopThree style="margin-top:10px;"/>
    <TopFour style="margin-top:10px;"/>
    <Footer />
  </div>
</template>

<script>
  import homeModal from "../../components/homeModal";
  import TopTwo from "./TopTwo";
  import TopThree from "./TopThree/index";
  import TopFour from "./TopFour/index";
  import Footer from "./Footer/index";
  
  import {
    findSum,
    indexTop,
    indexNum
  } from "../../api/api";
  export default {
    components: {
      homeModal,
      TopTwo,
      TopThree,
      TopFour,
      Footer
    },
    data() {
      return {
        // charts: [],
        tableData: [],
        getFindSumObj: null,
      };
    },

    created() {
      this.getFindSum();
      // this.getIndexTopList();
    },
    mounted() {
      // this.getIndexNum();
    },
    methods: {
      // 概览模块
      getFindSum() {
        this.$request(findSum).then((res) => {
          if (res.code === "200") {
            this.getFindSumObj = res.data;
          }
        });
      },
      //  前十
      getIndexTopList() {
        this.$request(indexTop).then((res) => {
          if (res.code === "200") {
            // newsTextVos 是文章   newsMp4Vos 是视频   mainVos 是量表
            let article = res.data.newsTextVos || [];
            let video = res.data.newsMp4Vos || [];
            let table = res.data.mainVos || [];
            let articleList = article.map((val, i) => {
              return {
                articleText: val.title,
                articleNum: val.readNum,
              };
            });
            let videoList = video.map((val, i) => {
              return {
                videoText: val.title,
                videoNum: val.readNum,
              };
            });
            // 三种类型数组的长度组成的数组
            let arr = [articleList.length, videoList.length, table.length]
            let newArr = []
            // 找到最长的数组
            let maxLength = Math.max(...arr)
            for (let i = 0; i < maxLength; i++) {
              newArr.push({
                id: i
              })
            }
            this.tableData = newArr.map((val, i) => {
              return {
                ...articleList[i],
                ...videoList[i],
                ...table[i],
              }
            })
          }
        });
      },
      // 最近7天
      getIndexNum() {
        this.$request(indexNum).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            // setTimeout(() => {
            //   this.$util.showInitCategory(
            //     this,
            //     "line",
            //     data.map((val) => {
            //       return val.time;
            //     }),
            //     data.map((val) => {
            //       return val.num;
            //     })
            //   );
            // }, 500);
            // this.$util.resizeCharts(this);
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .home {
    // padding-bottom:20px;
    z-index: 10;
    min-width: 1200px;
    .right_h {
      height: 434px;

      .table_h {
        height: 440px;
        overflow-y: auto;

        .rnking_num {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 10px;
        }

        .blue {
          color: #fff;
          background: #1890ff;
        }

        .grey {
          background: #f0f2f5;
        }
      }
    }

    .el-table::before {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0px;
    }
  }
</style>