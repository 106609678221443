<template>
<div id="mapEcharts" style="width:90%;min-width:400px;height:300px;margin:0 auto;margin-top:-80px;"></div>
</template>
<script>
	let echarts = require('echarts/lib/echarts')
	require('echarts/lib/chart/map')
	//下面的是需要的提示框
	require('echarts/lib/component/tooltip')
	require('echarts/lib/component/title')
	require('echarts/lib/component/legend')
	import CN from '@/common/china.js'
	export default{
		mounted(){
			this.draw ('mapEcharts');
		},
		methods:{
			draw (id) {
				console.log(CN)
				echarts.registerMap('CN', CN);
				let myChart = echarts.init(document.getElementById(id));
				myChart.setOption({
			        tooltip: {
						trigger: 'item',
						position:'top',
			            formatter:function(params){
			            var res = '名称: '+params.name+'<br/>'+ '用户量: '+params.data.value+'<br/>';
			            return res;
			            }
					},
					   visualMap: {
                        precision:0,                            //数据展示的小数精度，默认为0，无小数点
                        itemWidth:20,                           //图形的宽度，即长条的宽度。
                        itemHeight:70, 
                        margin:200, 
                        min: 0,
                        max: 5000,
                        left: 'left',
                        bottom: '15%',
                        text: ['高', '低'],//取值范围的文字
                        inRange: {
                            color: ['#e2ebf4',"#84A8FF", '#84A8FF']//取值范围的颜色
                        },
                        formatter: function (value) {                 //标签的格式化工具。
                            return '用户量: ' + Math.floor(value);                    // 范围标签显示内容。
                        },
                        show: true,//图注
                       },
				series: [{
                type: 'map',
                mapType: 'china',
                label: {
                    normal: {
                        show: false, //显示省份标签
                        textStyle: {
                            color: "blue"
                        } //省份标签字体颜色
                    },
                    emphasis: { //对应的鼠标悬浮效果
                        show: true,
                        textStyle: {
                            color: "#000"
                        }
                    }
                },
                aspectScale: 0.75,
                zoom: 1.2,
                itemStyle: {
                    normal: {
                        borderWidth: .5, //区域边框宽度
                        borderColor: '#eee', //区域边框颜色
                        // areaColor: "#ffefd5", //区域颜色
                        
                    },
                    emphasis: {
                        borderWidth: .5,
                        borderColor: '#eee',
                        areaColor: "#FFC96A",
                    }
                },
                data: [
					 {
						name:"南海诸岛",value:0,
						itemStyle:{
							normal:{opacity:0,label:{show:false}}
						}
					},
                    { name: '北京', selected: false, value: 1026 },
                    { name: '天津', selected: false, value: 22 },
                    { name: '上海', selected: false, value: 20 },
                    { name: '重庆', selected: false, value: 430 },
                    { name: '河北', selected: false, value: 3012 },
                    { name: '河南', selected: false, value: 66 },
                    { name: '云南', selected: false, value: 780 },
                    { name: '辽宁', selected: false, value: 88 },
                    { name: '黑龙江', selected: false, value: 90 },
                    { name: '湖南', selected: false, value: 101 },
                    { name: '安徽', selected: false, value: 11 },
                    { name: '山东', selected: false, value: 12 },
                    { name: '新疆', selected: false, value: 1069 },
                    { name: '江苏', selected: false, value: 1001 },
                    { name: '浙江', selected: false, value: 15 },
                    { name: '江西', selected: false, value: 3612 },
                    { name: '湖北', selected: false, value: 178},
                    { name: '广西', selected: false, value: 1501 },
                    { name: '甘肃', selected: false, value: 19 },
                    { name: '山西', selected: false, value: 20 },
                    { name: '内蒙古', selected: false, value: 515 },
                    { name: '陕西', selected: false, value: 98 },
                    { name: '吉林', selected: false, value: 23 },
                    { name: '福建', selected: false, value: 106 },
                    { name: '贵州', selected: false, value: 25 },
                    { name: '广东', selected: false, value: 4050 },
                    { name: '青海', selected: false, value: 270 },
                    { name: '西藏', selected: false, value: 280 },
                    { name: '四川', selected: false, value: 293 },
                    { name: '宁夏', selected: false, value: 308 },
                    { name: '海南', selected: false, value: 31 },
                    { name: '台湾', selected: false, value: 32 },
                    { name: '香港', selected: false, value: 33 },
                    { name: '澳门', selected: false, value: 34 }
                ] //各省地图颜色数据依赖value
            }],
            // dataRange: {
            //     x: 'left', //图例横轴位置
			// 	   y: 'bottom',
			// 	   show:false,  //图例纵轴位置
            //     splitList: [
            //         { start: 1, end: 1, label: '北京', color: '#B5E5FF' },
            //         { start: 3, end: 3, label: '上海', color: '#B5E5FF' },
            //         { start: 4, end: 4, label: '重庆', color: '#B5E5FF' },
            //         { start: 5, end: 5, label: '河北', color: '#B5E5FF' },
            //         { start: 6, end: 6, label: '河南', color: '#B5E5FF' },
            //         { start: 7, end: 7, label: '云南', color: '#B5E5FF' },
            //         { start: 8, end: 8, label: '辽宁', color: '#B5E5FF' },
            //         { start: 9, end: 9, label: '黑龙江', color: '#B5E5FF' },
            //         { start: 10, end: 10, label: '湖南', color: '#B5E5FF' },
            //         { start: 11, end: 11, label: '安徽', color: '#B5E5FF' },
            //         { start: 12, end: 12, label: '山东', color: '#B5E5FF' },
            //         { start: 13, end: 13, label: '新疆', color: '#B5E5FF' },
            //         { start: 14, end: 14, label: '江苏', color: '#B5E5FF' },
            //         { start: 15, end: 15, label: '浙江', color: '#B5E5FF' },
            //         { start: 16, end: 16, label: '江西', color: '#84A8FF' },
            //         { start: 17, end: 17, label: '湖北', color: '#B5E5FF' },
            //         { start: 18, end: 18, label: '广西', color: '#B5E5FF' },
            //         { start: 19, end: 19, label: '甘肃', color: '#B5E5FF' },
            //         { start: 20, end: 20, label: '山西', color: '#B5E5FF' },
            //         { start: 21, end: 21, label: '内蒙古', color: '#84A8FF' },
            //         { start: 22, end: 22, label: '陕西', color: '#B5E5FF' },
            //         { start: 23, end: 23, label: '吉林', color: '#B5E5FF' },
            //         { start: 24, end: 24, label: '福建', color: '#84A8FF' },
            //         { start: 25, end: 25, label: '贵州', color: '#B5E5FF' },
            //         { start: 26, end: 26, label: '广东省', color: '#84A8FF' },
            //         { start: 27, end: 27, label: '青海', color: '#B5E5FF' },
            //         { start: 28, end: 28, label: '西藏', color: '#B5E5FF' },
            //         { start: 29, end: 29, label: '四川', color: '#B5E5FF' },
            //         { start: 30, end: 30, label: '宁夏', color: '#B5E5FF ' },
            //         { start: 31, end: 31, label: '海南', color: '#B5E5FF' },
            //         { start: 32, end: 32, label: '台湾', color: '#B5E5FF' },
            //         { start: 33, end: 33, label: '香港', color: '#B5E5FF' },
            //         { start: 34, end: 34, label: '澳门', color: '#B5E5FF' }
            //     ]
            // }, //各省地图颜色

		      	});
			}
		}
	}
</script>